
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosError, AxiosResponse } from "axios";
import { Snackbar } from "@/lib/Snackbar";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";

export default Vue.extend({
  data: () => ({
    profileData: {
      name: "",
      taxId: "",
      phone: "",
      email: "",
    },
    charges: [
      { 
        billingType: "",
        description: "",
        totalValue: 0.00,
        transactionId: "",
        dueDate: "",
        cob_status: "",
        createdAt: "",
        paymentDate: ""
      }
    ],
    totalRows: 0,
    moment: moment,
    btnLoading: false,
    id: Number,
    errors: [],
    menu: false,
  }),
  created() {
    Overlay.show();
    Api.get("/v2/client/charges/details/" + this.$route.params.id)
      .then((response) => {
        this.profileData = response.data.body.customer;
        this.charges = response.data.body.rows;
        this.totalRows = response.data.body.count.__total;
      })

      .finally(() => {
        Overlay.hide();
      });
  },
  watch: {},
  computed: {},
  methods: {
    getId(): number {
      return Number(this.$route.params.id);
    },
    formatDate(date: string): string | null {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formataValor(data: number) {
      const valor = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(data);

      return valor.toString().replace("R$", "").trim();
    },
    showIconStatusPagto(item: any) {
      // "ACTIVE" "CANCELLED" "MANUAL_RECONCILIATION" "FAILED" "PAID", "ARCHIVED"
      if (item.cob_status === "CANCELED" || item.cob_status === "CANCELLED") {
        return "cancelada";
      }
      if (item.cob_status === "ARCHIVED") {
        return "arquivada";
      }
      if (item.cob_status === "PAID") {
        return "paga";
      }
      const ToDate = new Date();
      if (
        !item.paymentDate &&
        new Date(item.dueDate).getTime() > ToDate.getTime()
      ) {
        return "";
      }
      if (item.paymentDate) {
        return "paga";
      }
      if (
        !item.paymentDate &&
        new Date(item.dueDate).getTime() <= ToDate.getTime()
      ) {
        return "vencida";
      }

      if (
        !item.paymentDate &&
        new Date(item.dueDate).getTime() == ToDate.getTime()
      ) {
        return "ativa";
      }
    },
    limitText(limitField: string, limitNum: number) {
      return limitField.slice(0, limitNum) + (limitField.length > limitNum ? "..." : "");
    },
  },
});
