
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import { PreCadastro } from "@/models/PreCadastro";
import VueMask from "v-mask";
import Swal from "sweetalert2";
import VueRecaptcha from "vue-recaptcha";

Vue.use(VueMask);

interface Errors {
  nome?: string;
  email?: string;
  telefone?: string;
  relacao?: string;
  empresa?: string;
  cnpj?: string;
  porte?: string;
  segmento?: string;
  site?: string;
  interesses?: string;
}

export default Vue.extend({
  name: "Pre-Cadastro",
  data() {
    return {
      checkbox: false,
      checkboxStatus: false,
      dialogAbrirContaConsultor: true,
      valid: true,
      loadingData: true,
      formAberturaConta: {} as PreCadastro,
      lang: navigator.language,
      errors: {} as Errors,
      nameError: null,
      phoneError: null,
      relationError: null,
      companyError: null,
      siteError: "",
      sizeError: null,
      segmentError: null,
      interessesError: null,
      cnpjError: "",
      emailError: "",
      btnLoading: false,
      moment: moment,
      relacao: ["Sou o fundador", "Diretor", "Funcionário", "Outro"],
      portes: [
        "Microempreendedor Individual (MEI)",
        "Microempresa (ME)",
        "Empresa de Pequeno Porte (EPP)",
        "Empresa de Médio Porte",
        "Grande Empresa",
      ],
      segmentos: [
        "Acessórios automotivos",
        "Agência",
        "Agropecuária",
        "Alimentos",
        "Associação/Doação",
        "Brinquedos",
        "Cama, mesa e banho",
        "Consultoria/Advocacia",
        "Contabilidade",
        "Decoração",
        "Editoras e Gráficas",
        "Educação",
        "Energia solar",
        "Entretenimento",
        "Equipamentos",
        "Esportes",
        "Estética",
        "Fitness",
        "Funerária",
        "Games",
        "Indústria",
        "Joias e Relógios",
        "Moda",
        "Móveis",
        "Odontologia",
        "ONG e Oscip",
        "Outros",
        "Perfumaria e Cosméticos",
        "Petshop",
        "SaaS",
        "Saúde e convênios",
        "Seguradora",
        "Serviços",
        "Sexshop",
        "Tecnologia",
        "Telecom e Provedores",
        "Varejo",
      ],
      interesses: ["API PIX", "Cobranças", "Cripto", "Câmbio"],
      isMobile: false,
      emailRules: [
        (v: any) => !!v || "Informe seu e-mail",
        (v: any) => /.+@.+\..+/.test(v) || "Informe um e-mail válido",
      ],
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    };
  },
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  created() {
    this.isMobile = this.detectMobile();
  },
  methods: {
    onSubmitAbrirContaConsultor(token: string): void {
      this.errors = {}; // Reset errors to an empty object
      this.clearErrors();
      this.btnLoading = true;
      Overlay.show();
      Api.post("/v2/client/preregistro", this.formAberturaConta, {
        headers: {
          recaptcha: token,
        },
      })
        .then((response) => {
          window.location.replace(
            "https://ezzepay.com.br/pay/abrir-conta-em-analise/"
          );
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
          this.resetRecaptcha();
        })
        .finally(() => {
          this.btnLoading = false;
          Overlay.hide();
        });
    },
    onSubmit: function () {
      (this.$refs.invisibleRecaptcha as HTMLFormElement).execute();
    },
    onVerify: function (response: string) {
      this.onSubmitAbrirContaConsultor(response);
    },
    onExpired: function () {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      (this.$refs.invisibleRecaptcha as HTMLFormElement).reset();
    },
    detectMobile() {
      return screen.width <= 760;
    },
    clearError(field: keyof Errors) {
      this.errors[field] = '';
    },
    clearErrors() {
      this.errors = {
        nome: '',
        email: '',
        telefone: '',
        relacao: '',
        empresa: '',
        cnpj: '',
        porte: '',
        segmento: '',
        site: '',
        interesses: ''
      };
    },
    validateForm() {
      const interestInput = this.$refs.interests as any;
      const companySiteInput = this.$refs.companySiteInput as any;
      const checkboxInput = this.$refs.politics as any;
      const segmentOption = this.$refs.segmentOption as any;
      const portCompanyForm = this.$refs.portCompanyForm as any;
      const cnpjForm = this.$refs.cnpjForm as any;
      const companyNameInput = this.$refs.companyNameInput as any;
      const relationOptionSelect = this.$refs.relationOptionSelect as any;
      const telefoneInput = this.$refs.telefoneInput as any;
      const emailInput = this.$refs.emailInput as any;
      const nameInput = this.$refs.nameInput as any;
      let countErrors = 0;
      if (nameInput.value !== "" || nameInput.value !== null) {
        if (countErrors > 0) {
          countErrors -= 1;
        }
      } else {
        countErrors += 1;
        this.checkbox = false;
      }
      if (emailInput.value !== "" && this.formAberturaConta.email !== "") {
        if (countErrors > 0) {
          countErrors -= 1;
        }
      } else {
        countErrors += 1;
        this.checkbox = false;
      }
      if (telefoneInput.value !== "" && this.formAberturaConta.telefone !== "") {
        if (countErrors > 0) {
          countErrors -= 1;
        }
      } else {
        countErrors += 1;
        this.checkbox = false;
      }

      if (relationOptionSelect.value !== undefined) {
        if (countErrors > 0) {
          countErrors -= 1;
        }
      } else {
        countErrors += 1;
        this.checkbox = false;
      }
      if (this.formAberturaConta.empresa != "" || companyNameInput.value != "") {
        if (countErrors > 0) {
          countErrors -= 1;
        }
      } else {
        countErrors += 1;
        this.checkbox = false;
        companyNameInput.focus();
      }
      if (this.formAberturaConta.cnpj != "" || cnpjForm.value != "") {
        if (countErrors > 0) {
          countErrors -= 1;
        }
      } else {
        countErrors += 1;
        this.checkbox = false;
        cnpjForm.focus();
      }
      if (portCompanyForm.value !== undefined) {
        if (countErrors > 0) {
          countErrors -= 1;
        }
      } else {
        countErrors += 1;
        this.checkbox = false;
      }
      if (segmentOption.value !== undefined) {
        if (countErrors > 0) {
          countErrors -= 1;
        }
      } else {
        countErrors += 1;
        this.checkbox = false;
      }
      if (companySiteInput.value !== undefined && companySiteInput.value !== "" || companySiteInput.value !== null) {
        if (countErrors > 0) {
          countErrors -= 1;
        }
      } else {
        countErrors += 1;
        this.checkbox = false;
      }
      if (interestInput.value !== undefined && companySiteInput.value !== undefined && companyNameInput.value !== "") {
        let interestValue = interestInput.value || "";
        if (interestValue.length > 0 && companySiteInput.value.length > 0) {
          if (countErrors > 0) {
            countErrors -= 1;
          }
        }
      } else {
        countErrors += 1;
        this.checkbox = false;
      }
      if (countErrors === 0 && this.checkboxStatus) {
        this.checkbox = true;
      }
    },
  },
});
