<template>
  <div id="locale-switcher" :class="{ 'login-page': isLoginPage, 'criarconta-page': isCriarContaPage }">
    <v-row class="my-6 mobile-buttons" no-gutters>
      <v-col
        v-for="locale in supportedLocales"
        :key="locale.code"
        cols="4"
        sm="4"
      >
        <button
          type="button"
          @click="changeLocale(locale.code)"
          :class="{ selected: $i18n.locale === locale.code }"
        >
          {{ locale.name }}
        </button>
      </v-col>
    </v-row>
  </div>
</template>


<script>
export default {
  data() {
    return {
      supportedLocales: [
        { code: "en", name: "English" },
        { code: "pt", name: "Português" },
        { code: "es", name: "Español" },
      ],
      selectedLanguage: localStorage.getItem("selectedLanguage") || "pt",
    };
  },

  created() {
    if (!this.$i18n.locale) {
      this.changeLocale(this.selectedLanguage);
    }
  },

  computed: {
    isLoginPage() {
      return this.$route.path === '/login';
    }
  },

  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("selectedLanguage", locale);
    },
  },
};
</script>
<style scoped>
.selected {
  font-weight: bold;
  color: #a91a83;
}

.login-page .selected, .criarconta-page .selected {
  color: #ffffff;
}

.criarconta-page .selected {
  color: #ffffff;
}

@media (max-width: 800px) {
  .mobile-buttons {
    display: flex !important;
    flex-direction: column;
  }
}
</style>
