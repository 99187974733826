
import Vue from "vue";
import Api from "@/services/Api";
import { Overlay } from "@/lib/Overlay";
import { AxiosResponse } from "axios";
import { VMoney } from "v-money";
import moment from "moment";
import { TableOptions } from "@/models/TableOptions";
import { SwapQuote } from "@/models/SwapQuote";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";

//@ts-ignore

export interface Swaps {
  id: string;
  swap: any;
}
export default Vue.extend({
  name: "NewSwap",

  data() {
    return {
      loadingBalance: false,
      saldoDisponivel: "0,00",
      showSaldo: false,
      transactionPin: "",
      btnloading: false,
      OkCreateSwap: false,
      dialogConfirmSwap: false,
      QuoteRespJWT: [],
      showAmountToPayUSDT: true,
      showNetInverseRateAmountOut: false,
      netInverseRateAmountOut: "R$ 1 = 0,000000 USDT",
      netRateAmountOut: "1 USDT = R$ 0,000000",
      amountIn: {
        amountIn: "0.0000",
        amountInFormatado: "0,00",
        amountInUSD: "0,00",
        amountInEUR: "0,00",
      },
      ticketPrice: "1 USDT = R$ 0,000000",
      netAmountOut: {
        netAmountOut: "0.000000",
        netAmountOutFormatado: "0,00",
        netAmountOutEURFormatado: "0,00",
      },
      dialogSwap: false,
      serviceTax: "0,00",
      serviceTaxPerc: "0",

      withdrawTax: "0,00",
      blockchainTax: "0",
      addressOut: "",
      addressOutPlaceholder: "0x48d17...",
      defaultBtnCurrencyIn: {
        icon: require("@/assets/images/brl.svg"),
        namePt: "Real",
        sigla: "BRL",
      },
      defaultBtnCurrencyOut: {
        icon: require("@/assets/images/usdt.svg"),
        namePt: "Tether",
        sigla: "USDT",
      },
      defaultBtnNetWorkOut: {
        icon: require("@/assets/images/eth.svg"),
        namePt: "Ethereum (ERC20)",
      },
      rules: {
        required: (value: string) => !!value || "Required.",
      },
      errors: [] as any,
      swapExactFiatForCrypto: false,
      swapFiatForExactCrypto: false,
      formQuote: {} as SwapQuote,

      loadingDesserts: true,
      totalDesserts: 0,
      options: {} as TableOptions,
      search: "",
      moment: moment,
      lang: navigator.language,
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      timeout: 0,
      markets: [], //JSON.parse(localStorage.getItem("SwapMarkets")),
      isMobile: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      moneyBTC: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 6,
        masked: false /* doesn't work with directive */,
      },

      currencies: [],
      marketsBTC: [] as any,
      marketsUSDT: [] as any,
      defaultMarket: [] as any,
      user: "",
      amountInBrl: "",
      netAmountOutBTC: "",
      okToQuote: true,
      countDown: 20,
      countDownCount: 0,
      countDownStart: true,
    };
  },
  created() {
    this.isMobile = this.detectMobile();

    this.getCurrencies();
    this.formQuote.currencyIn = "BRL";
    this.formQuote.currencyOut = "USDT";
    this.formQuote.networkOut = "eth-mainnet";
    this.formQuote.netAmountOut = this.netAmountOut.netAmountOut;
    // this.countDownTimer();
    this.countDownStart = false;
  },

  methods: {
    openDialoQuote() {
      this.resetFieldsQuote();
      this.getBalanceSwap();

      this.dialogSwap = true;
      this.countDownStart = false;
    },
    resetFieldsQuote() {
      this.dialogConfirmSwap = false;
      this.OkCreateSwap = false;
      this.btnloading = false;
      this.amountInBrl = "";
      this.netAmountOutBTC = "";
      let inputNetAmountOut =
        (document.getElementById("BTC") as HTMLInputElement) || {};
      inputNetAmountOut.value = "0";
      let inputAmountIn =
        (document.getElementById("BRL") as HTMLInputElement) || {};
      inputAmountIn.value = "0";
      this.formQuote.currencyIn = "BRL";
      this.formQuote.currencyOut = "USDT";
      this.formQuote.networkOut = "eth-mainnet";
      this.formQuote.amountIn = "";
      this.formQuote.netAmountOut = "";

      this.netInverseRateAmountOut = "R$ 1 = 0,000000 USDT";
      this.netRateAmountOut = "1 USDT = R$ 0,000000";
      (this.amountIn = {
        amountIn: "0.0000",
        amountInFormatado: "0,00",
        amountInUSD: "0,00",
        amountInEUR: "0,00",
      }),
        (this.ticketPrice = "1 USDT = R$ 0,000000");
      this.netAmountOut = {
        netAmountOut: "0.00000000",
        netAmountOutFormatado: "0,00",
        netAmountOutEURFormatado: "0,00",
      };
      this.transactionPin = "";
      this.QuoteRespJWT = [];
      this.serviceTax = "0,00";
      this.serviceTaxPerc = "0";
      this.withdrawTax = "0,00";
      this.blockchainTax = "0";
      this.addressOut = "";
      this.countDownStart = false;
      this.countDownCount = 0;
    },
    openDialogConfirmSwap() {
      if (this.addressOut.length < 10) {
        this.errors["addressOut"] = "Carteira inválida";
        return false;
      }
      this.dialogConfirmSwap = true;
      //  this.dialogSwap = false;
    },
    cancelConfirmSwap() {
      this.resetFieldsQuote();
      this.transactionPin = "";
      this.dialogConfirmSwap = false;
      this.dialogSwap = true;
    },
    cancelSwap() {
      this.resetFieldsQuote();
      this.OkCreateSwap = false;
      this.dialogSwap = false;
    },
    createSwap() {
      this.btnloading = true;

      this.errors = [];

      if (this.transactionPin.length < 6) {
        this.errors["transactionPin"] = "Pin de transações inválido";
        this.btnloading = false;
        return false;
      }
      this.OkCreateSwap = false;
      this.countDownStart = false;

      Overlay.show();
      this.btnloading = true;
      this.dialogConfirmSwap = false;
      Api.post("/v2/client/cripto/swaps/swap", {
        transactionPin: this.transactionPin,
        addressOut: this.addressOut,
        userId: sessionStorage.getItem("userCriptoId"),
        quoteData: this.QuoteRespJWT,
      })
        .then((response) => {
          this.dialogConfirmSwap = false;
          this.dialogSwap = false;
          this.addressOut = "";
          Swal.fire({
            title: this.$t("components.preCadastro.template.form.ConversionCreatedSuccessfully") as string,
            text: this.$t("components.preCadastro.template.form.PleaseWaitProcessing") as string,
            icon: "success",
          });
          this.$emit("callmethod", 0);
          this.resetFieldsQuote();
          this.countDownStart = false;
          this.dialogSwap = false;
          this.dialogConfirmSwap = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            Swal.fire({
              title: "Opps",
              text: data.body.error,
              icon: "error",
            });
          }
          this.dialogConfirmSwap = false;
          this.resetFieldsQuote();
          this.OkCreateSwap = false;
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.btnloading = false;
          this.OkCreateSwap = true;
          this.okToQuote = true;
          this.dialogConfirmSwap = false;
        });
    },
    //crypto para real
    fnSwapFiatForExactCrypto() {
      this.errors = [];

      //console.log("swapFiatForExactCrypto");
      this.swapExactFiatForCrypto = false;
      this.swapFiatForExactCrypto = true;

      this.formQuote.type = "swapFiatForExactCrypto";
      //this.getQuote();
      //this.formQuote.amountIn = "";
    },
    //real para crypto
    fnSwapExactFiatForCrypto() {
      this.errors = [];

      // console.log("swapExactFiatForCrypto");
      this.swapExactFiatForCrypto = true;
      this.swapFiatForExactCrypto = false;
      this.formQuote.type = "swapExactFiatForCrypto";
      // this.formQuote.netAmountOut = "";
      //console.log(this.formQuote.amountIn);
      //console.log(this.formQuote.amountIn.length);

      // this.getQuote();
    },
    makeBtnCurrencIn(icon: string, namePt: string, sigla: string) {
      this.defaultBtnCurrencyIn.icon = require("@/assets/images/" +
        icon.toLocaleLowerCase() +
        ".svg");
      this.defaultBtnCurrencyIn.namePt = namePt;
      this.defaultBtnCurrencyIn.sigla = sigla;

      this.formQuote.currencyIn = sigla;
    },
    makeBtnCurrencyOut(item: any) {
      this.defaultBtnCurrencyOut.icon = require("@/assets/images/" +
        item.currency.toLocaleLowerCase() +
        ".svg");
      this.defaultBtnCurrencyOut.namePt = item.name;
      this.defaultBtnCurrencyOut.sigla = item.currency;
      this.defaultMarket = item.deposit.networks;
      /*  if (item.currency === "USDT") {
        this.moneyBTC = {
          decimal: ".",
          thousands: "",
          prefix: "",
          suffix: "",
          precision: 2,
          masked: false,
        };
      }
      */
      if (item.currency === "BTC") {
        // this.defaultMarket = this.marketsBTC;
        /*  this.moneyBTC = {
          decimal: ".",
          thousands: "",
          prefix: "",
          suffix: "",
          precision: 8,
          masked: false,
        };
*/
        // console.log(this.defaultMarket[0]);
        this.defaultBtnCurrencyOut.icon = require("@/assets/images/" +
          item.currency.toLocaleLowerCase() +
          ".svg");
        this.defaultBtnCurrencyOut.namePt = item.name;
        this.defaultBtnCurrencyOut.sigla = item.currency;
      }
      this.defaultBtnNetWorkOut.icon = require("@/assets/images/" +
        this.defaultMarket[0].currencySymbol.toLocaleLowerCase() +
        ".svg");
      this.defaultBtnNetWorkOut.namePt = this.defaultMarket[0].description;

      this.addressOutPlaceholder = this.defaultMarket[0].placeholder;

      this.formQuote.currencyOut = item.currency;
      this.formQuote.networkOut = this.defaultMarket[0].nameId;
      this.getQuote();
    },
    makeBtnNetworkOut(item: any) {
      this.addressOut = "";
      this.addressOutPlaceholder = item.placeholder;

      this.defaultBtnNetWorkOut.icon = require("@/assets/images/" +
        item.currencySymbol.toLocaleLowerCase() +
        ".svg");
      this.defaultBtnNetWorkOut.namePt = item.description;

      this.formQuote.networkOut = item.nameId;

      // this.getQuote();
    },

    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    getMarkets() {
      this.loadingDesserts = true;
      Overlay.show();
      Api.get("/v2/client/cripto/swaps/markets")
        .then((response) => {
          this.markets = response.data.data;

          const markets = response.data.data;
          for (let index = 0; index < markets.length; index++) {
            const element = markets[index];

            if (element.baseCurrency.currency === "USDT") {
              this.marketsUSDT = markets[index].baseCurrency.withdraw
                .networks as any;
              this.defaultMarket = this.marketsUSDT;
            }
            if (element.baseCurrency.currency === "BTC") {
              this.marketsBTC = markets[index].baseCurrency.withdraw.networks;
            }
          }
        })
        .finally(() => {
          Overlay.hide();
          this.loadingDesserts = false;
          this.dialogSwap = false;
        });
    },
    getCurrencies() {
      this.loadingDesserts = true;
      Overlay.show();
      Api.get("/v2/client/cripto/swaps/currencies")
        .then((response) => {
          this.currencies = response.data.data;

          localStorage.removeItem("SwapCurrencies");
          localStorage.setItem(
            "SwapCurrencies",
            JSON.stringify(response.data.data)
          );
        })
        .finally(() => {
          this.getMarkets();
          Overlay.hide();
          this.loadingDesserts = false;
        });
    },

    removeFormatacaoMoeda($str: string) {
      return $str.replace(/\D/g, "");
    },
    validaAmountIn() {
      const valorAmountIn = this.removeFormatacaoMoeda(this.amountInBrl);

      if (
        valorAmountIn === "000" ||
        valorAmountIn === "0" ||
        valorAmountIn === "00" ||
        valorAmountIn === ""
      ) {
        return false;
      }
      return true;
    },
    validaAmountOut() {
      const valorNetAmountOut = this.removeFormatacaoMoeda(
        this.netAmountOutBTC
      );
      if (
        valorNetAmountOut === "000000000" ||
        valorNetAmountOut === "0000000" ||
        valorNetAmountOut === "000" ||
        valorNetAmountOut === "0" ||
        valorNetAmountOut === "00" ||
        valorNetAmountOut === ""
      ) {
        return false;
      }
      return true;
    },
    countDownTimer() {
      if (this.countDownStart && this.countDownCount <= 5) {
        setTimeout(() => {
          if (this.countDown > 0 && this.countDownStart) {
            this.countDown -= 1;
            this.countDownTimer();
          }

          //console.log(this.countDown);
        }, 1000);

        if (this.countDown == 0) {
          this.countDownCount += 1;
          this.countDown = 20;
          // console.log("getQuote(" + this.countDownCount + ")");
          this.countDownStart = false;
          this.getQuote();
        }
        if (this.countDownCount == 5) {
          // console.log("finalizou");
          this.resetFieldsQuote();
          this.countDownStart = false;
        }
        // console.log(this.countDownStart);
      }
    },
    getQuote() {
      this.errors = [];
      this.QuoteRespJWT = [];
      this.btnloading = true;
      this.OkCreateSwap = false;

      setTimeout(() => {
        //deault values
        let postParams = {};
        if (this.swapExactFiatForCrypto) {
          if (!this.validaAmountIn()) {
            return false;
          }
          postParams = {
            amountIn: this.amountInBrl
              .replace("R$", "")
              .replace(" ", "")
              .trim(),
            currencyIn: this.formQuote.currencyIn,
            currencyOut: this.formQuote.currencyOut,
            networkOut: this.formQuote.networkOut,
            user: sessionStorage.getItem("userCriptoId"),
            type: this.formQuote.type,
          };
        } else {
          if (!this.validaAmountOut()) {
            return false;
          }
          postParams = {
            netAmountOut: this.netAmountOutBTC,
            currencyIn: this.formQuote.currencyIn,
            currencyOut: this.formQuote.currencyOut,
            networkOut: this.formQuote.networkOut,
            user: sessionStorage.getItem("userCriptoId"),
            type: this.formQuote.type,
          };
        }
        this.loadingDesserts = true;
        this.okToQuote = false;
        Api.get("/v2/client/cripto/swaps/quote", {
          params: postParams,
        })
          .then((response) => {
            const dataResp = jwtDecode(response.data.body.quote) as any;
            this.QuoteRespJWT = response.data.body.quote;
            //console.log(dataResp);
            if (dataResp.data.status === "ok") {
              // console.log(dataResp.data.data);

              this.$nextTick(() => {
                let inputNetAmountOut = document.getElementById(
                  "BTC"
                ) as HTMLInputElement;
                inputNetAmountOut.value =
                  dataResp.data.data.quote.netAmountOut.netAmountOut;
                let inputAmountIn = document.getElementById(
                  "BRL"
                ) as HTMLInputElement;
                inputAmountIn.value =
                  dataResp.data.data.quote.amountIn.amountInFormatado;
              });
              //this.netAmountOutBTC = "56.87";
              /* this.netAmountOutBTC =
                    dataResp.data.data.quote.netAmountOut.netAmountOut;
*/

              this.netAmountOut = dataResp.data.data.quote.netAmountOut;
              this.amountIn = dataResp.data.data.quote.amountIn;
              // console.log(this.amountInBrl);

              //this.formQuote.netAmountOut = "0.00000";

              // this.$refs.netAmountOut.reset();

              this.serviceTax = dataResp.data.data.quote.fees.service.amount;
              this.serviceTaxPerc =
                dataResp.data.data.quote.fees.service.percentage.toString();

              this.blockchainTax =
                dataResp.data.data.quote.fees.networkOut.amount +
                " " +
                dataResp.data.data.quote.fees.networkOut.currency;
              this.netRateAmountOut =
                "1 " +
                dataResp.data.data.quote.netRateCurrencyOut +
                " = R$  " +
                dataResp.data.data.quote.netRateAmountOut;

              this.netInverseRateAmountOut =
                "R$ 1 = " +
                dataResp.data.data.quote.netInverseRateAmountOut +
                " " +
                dataResp.data.data.quote.netRateCurrencyOut;

              this.withdrawTax = response.data.body.withdrawTax;
              this.OkCreateSwap = true;
              // this.cardRevealDetalhes = true;
              this.countDownStart = true;
              this.countDown = 20;
              this.countDownTimer();
            }
          })
          .catch((error: any) => {
            /* eslint-disable */
            this.OkCreateSwap = false;
            const response = error.response as AxiosResponse;
            const status = response.status;
            const data = response.data;  

            if (status === 422) {
              /* Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
              */
              /* eslint-disable */
              const _this = this;
              setTimeout(function(){
                _this.netAmountOutBTC = data.body.quote.data.quote.netAmountOut.netAmountOut;
              }, 500);
              this.errors = data.body.errors;
            } else {
              Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            }
          })
          .finally(() => {
            Overlay.hide();

            this.loadingDesserts = false;
            this.btnloading = false;
            setTimeout(() => {
              this.okToQuote = true;
            }, 3000);
          });
      }, 500);
    },
    getCurrencySymbol(symbol: string) {
      //console.log(symbol);
      return require(`@/assets/images/${symbol.toLocaleLowerCase()}.svg`);
    },
    getBalanceSwap() {
      this.loadingBalance = true;

      Api.get("/v2/client/cripto/swaps/balance")
        .then((response) => {
          this.saldoDisponivel = response.data.body.swapBalanceFormatado;
        })
        .finally(() => {
          this.loadingBalance = false;
        });
    },
    doDelayedQuote() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.getQuote();
        this.okToQuote = true;
      }, 500);
    },
  },
  watch: {
    amountInBrl: {
      handler() {
        if (!this.validaAmountIn()) {
          return false;
        }
        if (this.okToQuote) {
          this.fnSwapExactFiatForCrypto();
          this.doDelayedQuote();
        }
      },
    },
    netAmountOutBTC: {
      handler() {
        if (!this.validaAmountOut()) {
          return false;
        }
        if (this.okToQuote) {
          this.fnSwapFiatForExactCrypto();
          this.doDelayedQuote();
        }
      },
    },
  },
  directives: { money: VMoney, moneyBTC: VMoney },
});
