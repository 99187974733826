
import Vue from "vue";
import axios from "axios";
//import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import VueClipboard from "vue-clipboard2";

import { Alert } from "@/lib/Alert";

Vue.use(VueClipboard);
export default Vue.extend({
  name: "CobrancaBolePix",

  data() {
    return {
      CobrancaBolePix: {
        interestValue: "",
        multa: 0,
        qrCodeBoletoImg: "",
        totalValue: 0,
        value: 0,
        status: "",
        customer: {
          email: "",
          name: "",
        },
        benficiario: {
          nome: "",
          cpf_cnpj: "",
          telefone: "",
          email: "",
          cep: "",
          logradouro: "",
          numero: "",
          bairro: "",
          cidade: "",
          uf: "",
        },
      },
      hideSelectPayments: true,
      urlQrCode: "",
      loadingData: true,
      transctionId: "",
      alert: {} as Alert,
      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
      isHiddenBoleto: false,
      isHiddenQrCode: false,
      statusMessage: "",
      dueDateTrait: "",
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    //this.moduloInfo();
    this.loadChargeInfo();
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    /* eslint-disable  */
    loadChargeInfo() {
      const id = this.$route.params.id;
      this.transctionId = id;
      //this.showAlert();
      //Overlay.show();
      axios.get(process.env.VUE_APP_API + '/cob/f/' + id)
        .then((response) => {
          console.log(response.data);
          this.CobrancaBolePix = response.data;
          this.urlQrCode = "data:image/png;base64," + this.CobrancaBolePix.qrCodeBoletoImg;
          this.statusMessage = this.showStatusCharge(response.data.em_atraso);
          this.traitsDate(response.data.dueDate);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openCharge() {
      const id = this.$route.params.id;
      const urlPage = process.env.VUE_APP_API + "/charges/b/" + id;
      window.open(urlPage, '_blank');
    },
    setIsHiddenBoleto() {
      this.isHiddenBoleto = true;
      this.isHiddenQrCode = false;
    },
    setIsHiddenQrCode() {
      this.isHiddenBoleto = false;
      this.isHiddenQrCode = true;
    },
    traitsDate(dueDate: string) {
      const dueDateArr = dueDate.split(" ");
      this.dueDateTrait = dueDateArr[0];
    },
    doCopy(str: string) {
      this.$copyText(str).then(function (e) {
        Snackbar.show("Copiado com sucesso!");
      });
    },
    onCopy() {
      // console.log(str);

      Snackbar.show("Copiado com sucesso!");
    },
    onCopyError() {
      // console.log(str);

      Snackbar.show("Erro ao copiar para área de transferência", "error");
    },
    showAlert(show = false, message = "", type = "error"): void {
      this.alert = {
        show,
        type,
        message,
      };
    },
    showStatusCharge(em_atraso: string) {
      this.statusMessage = em_atraso != "Atrasada" ? "<div style='background-color: orange !important;padding: 10px;border-radius: 16px;margin-right: 10px;float: left;'> </div> <span style='color: orage;font-weight: bold'>Aguardando pagamento</span>" : "<div style='background-color: #ff0000 !important;padding: 10px;border-radius: 16px;margin-right: 10px;float: left;'> </div><span style='color: red;font-weight: bold'>Cobrança vencida</span>";
      if(this.CobrancaBolePix.status === "PAID"){
        this.hideSelectPayments = false;
      }
      return (this.CobrancaBolePix.status === "PAID")? '<div style="background-color: #00ff2b !important;padding: 10px;border-radius: 16px;margin-right: 10px;float: left;"> </div> <span style="color: orage;font-weight: bold">Pagamento Confirmado</span>' : this.statusMessage;
    },
  },
});
