
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { CriarConta } from "@/models/CriarConta";
import Axios, { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import { Overlay } from "@/lib/Overlay";
import router from "@/router";
import VueRecaptcha from "vue-recaptcha";
import VueMask from "v-mask";
import { Snackbar } from "@/lib/Snackbar";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue"; 

Vue.use(VueMask);

export default Vue.extend({
  name: "criarConta",
  data: () => ({
    stepRegister: 1,
    dialogWhoCPF: false,
    ckTermos: false,
    ckTermoAdesao: false,
    ckTarifa: true,
    emailRules: [
      (v: any) => !!v || "Informe seu e-mail",
      (v: any) => /.+@.+\..+/.test(v) || "Informe um e-mail válido",
    ],
    passwordRules: {
      show: false,
      valid_password: false,
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_lowercase: false,
      contains_special_character: false,
    },
    validInfoBasic: false,

    validCPFCNPJ: true,
    valid: true,
    showDataNascimento: false,
    hasSent: false,
    msgHasSent: "",
    form: {} as CriarConta,
    errors: [],
    btnLoading: false,
    primeiroNome: "",
    lembraLogin: false,
    clientIp: "0.0.0.0",
    sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    showpass: false,
    readonlyCidade: false,
    readonlyUF: false,
  }),
  components: {
    LocaleSwitcher, 
    "vue-recaptcha": VueRecaptcha,
  },
  created() {
    //verifica o codigo fornecido
    const idCadastro = this.$route.params.id;
    if (
      idCadastro == "undefined" ||
      idCadastro == null ||
      idCadastro.length < 5
    ) {
      router.push("/login");
    }
    this.form.tokenCadastro = idCadastro;
    const afiliado = sessionStorage.getItem("afiliado");
    if (afiliado != "undefined" || afiliado != null) {
      this.form.afiliado = Number(afiliado);
    } else {
      this.form.afiliado = 0;
    }
  },
  methods: {
    storage() {
      sessionStorage.setItem("dadosConta", JSON.stringify(this.form));
    },

    ckDoc: function (token: string) {
      const wrapper = this.$refs.formCriarContaValidaCPFCNPJ as any;
      wrapper?.validate();

      if (!this.form.cpfcnpj) {
        Snackbar.show(
          "Informe corretamente seu CPF ou CNPJ para continuar",
          "error"
        );
        this.resetRecaptcha();
        return false;
      } else if (this.form.cpfcnpj.length < 10) {
        Snackbar.show(
          "Informe corretamente seu CPF ou CNPJ para continuar",
          "error"
        );
        this.resetRecaptcha();
        return false;
      }

      if (this.form.cpfcnpj.length == 11) {
        if (!this.form.nascimento) {
          Snackbar.show("Informe sua data de nascimento", "error");
          this.resetRecaptcha();
          return false;
        } else if (this.form.nascimento.length != 10) {
          Snackbar.show("Informe sua data de nascimento", "error");
          this.resetRecaptcha();
          return false;
        }
      }

      this.errors = [];
      this.btnLoading = true;
      Overlay.show();
      Api.post("/v2/client/register/checkDoc", this.form, {
        headers: {
          recaptcha: token,
        },
      })
        .then((response) => {
          this.form.nome = response.data.body.nomeRazaoSocial;
          this.form.cpfcnpj = response.data.body.numInscricao;
          this.primeiroNome = response.data.body.primeiroNome;
          this.form.tipo = response.data.body.tipoPessoa;
          this.stepRegister = 4;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          this.btnLoading = false;
          Overlay.hide();
          this.resetRecaptcha();
        });
    },
    ckUser: function (token: string) {
      this.errors = [];
      const wrapper = this.$refs.formCriarContaValidaUsuario as any;
      wrapper?.validate();
      if (!this.valid) {
        this.resetRecaptcha();
        return false;
      }
      if (!this.form.usuario) {
        Snackbar.show("Você precisa informar um usuário.", "error");
        this.resetRecaptcha();
        return false;
      } else if (this.form.usuario.length < 8) {
        Snackbar.show("Você precisa informar um usuário.", "error");
        this.resetRecaptcha();
        return false;
      }
      this.btnLoading = true;
      Overlay.show();
      Api.post(
        "/v2/client/register/checkUser",
        { usuario: this.form.usuario, tokenCadastro: this.form.tokenCadastro },
        {
          headers: {
            recaptcha: token,
          },
        }
      )
        .then((response) => {
          this.storage();
          this.form.cep = "";
          this.stepRegister = 6;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
          this.resetRecaptcha();
        })
        .finally(() => {
          this.btnLoading = false;
          //  console.log("finalizou");

          Overlay.hide();
          this.resetRecaptcha();
        });
    },
    checkCEP: function (token: string) {
      this.errors = [];
      if (this.form.cep.length == 9) {
        this.btnLoading = true;
        Overlay.show();
        Api.post(
          "/v2/client/register/checkCEP?internal=true",
          { cep: this.form.cep, tokenCadastro: this.form.tokenCadastro },
          {
            headers: {
              recaptcha: token,
            },
          }
        )
          .then((response) => {
            this.form.logradouro = response.data.body.logradouro;
            this.form.logradouroComplemento = response.data.body.complemento;
            this.form.logradouroBairro = response.data.body.bairro;
            this.form.logradouroCidade = response.data.body.localidade;
            this.form.logradouroUF = response.data.body.uf;
            if (this.form.logradouroCidade.length > 3) {
              this.readonlyCidade = true;
            }
            if (this.form.logradouroUF.length == 2) {
              this.readonlyUF = true;
            }
            this.$nextTick(() => {
              const wrapper = this.$refs.refLogradouro as any;

              window.setTimeout(() => wrapper?.focus(), 0);
            });

            this.storage();
          })
          .catch((error: any) => {
            const response = error.response as AxiosResponse;
            const status = response.status;
            const data = response.data;

            if (status === 422) {
              this.errors = data.body.errors;
            } else {
              Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            }
          })
          .finally(() => {
            this.btnLoading = false;
            //  console.log("finalizou");

            Overlay.hide();
            this.resetRecaptcha();
          });
      }
    },
    ckLocalidade: function (token: string) {
      this.errors = [];

      const wrapper = this.$refs.formCriarContaLocalidade as any;
      wrapper?.validate();
      if (!this.valid) {
        this.resetRecaptcha();
        return false;
      }

      if (!this.form.cep) {
        Snackbar.show("Informe seu CEP", "error");
        this.resetRecaptcha();
        return false;
      } else if (this.form.cep.length < 8) {
        Snackbar.show("Informe seu CEP", "error");
        this.resetRecaptcha();
        return false;
      }
      if (!this.form.logradouro) {
        Snackbar.show("Informe seu Endereço", "error");
        this.resetRecaptcha();
        return false;
      } else if (this.form.logradouro.length < 3) {
        Snackbar.show("Informe seu Endereço", "error");
        this.resetRecaptcha();
        return false;
      }
      if (!this.form.logradouroNum) {
        Snackbar.show("Informe o Número", "error");
        this.resetRecaptcha();
        return false;
      } else if (this.form.logradouroNum.length < 1) {
        Snackbar.show("Informe o Número", "error");
        this.resetRecaptcha();
        return false;
      }
      if (!this.form.logradouroBairro) {
        Snackbar.show("Informe o Bairro", "error");
        this.resetRecaptcha();
        return false;
      } else if (this.form.logradouroBairro.length < 3) {
        Snackbar.show("Informe o Bairro", "error");
        this.resetRecaptcha();
        return false;
      }

      if (!this.form.logradouroCidade) {
        Snackbar.show("Informe a cidade", "error");
        this.resetRecaptcha();
        return false;
      } else if (this.form.logradouroCidade.length < 3) {
        Snackbar.show("Informe a cidade", "error");
        this.resetRecaptcha();
        return false;
      }
      if (!this.form.logradouroUF) {
        Snackbar.show("Informe a UF", "error");
        this.resetRecaptcha();
        return false;
      } else if (this.form.logradouroUF.length != 2) {
        Snackbar.show("Informe a UF", "error");
        this.resetRecaptcha();
        return false;
      }

      this.$nextTick(() => {
        Overlay.show();

        this.storage();
        window.setTimeout(() => (this.stepRegister = 7), 500);
        window.setTimeout(() => Overlay.hide(), 100);
      });
    },
    checkPassword() {
      this.errors = [];
      this.passwordRules.password_length = this.form.senha.length;

      const format = /[!@#$%^&*(){}[\]<>?/|-]/;

      if (this.passwordRules.password_length > 8) {
        this.passwordRules.contains_eight_characters = true;
      } else {
        this.passwordRules.contains_eight_characters = false;
      }

      this.passwordRules.contains_number = /\d/.test(this.form.senha);
      this.passwordRules.contains_uppercase = /[A-Z]/.test(this.form.senha);

      this.passwordRules.contains_lowercase = /[a-z]/.test(this.form.senha);

      this.passwordRules.contains_special_character = format.test(
        this.form.senha
      );

      if (this.form.senha === "123456" || this.form.senha === "456789") {
        this.errors = JSON.parse(
          JSON.stringify({ senha: "Não utilize números sequenciais." })
        );
        this.passwordRules.valid_password = false;
      }

      if (
        this.passwordRules.contains_eight_characters === true &&
        this.passwordRules.contains_special_character === true &&
        this.passwordRules.contains_uppercase === true &&
        this.passwordRules.contains_lowercase === true &&
        this.passwordRules.contains_number === true
      ) {
        this.passwordRules.valid_password = true;
      } else {
        this.passwordRules.valid_password = false;
      }
    },
    ckEmail(token: string) {
      const valido = true;

      const wrapper = this.$refs.formCriarContaValidaCkEmail as any;
      wrapper?.validate();

      if (!this.form.email) {
        Snackbar.show("Informe seu email", "error");
        this.resetRecaptcha();
        return false;
      }

      Overlay.show();

      this.btnLoading = true;

      Api.post(
        "/v2/client/register/checkEmail",
        { email: this.form.email, tokenCadastro: this.form.tokenCadastro },
        {
          headers: {
            recaptcha: token,
          },
        }
      )
        .then((response) => {
          this.valid = true;
          this.stepRegister = 2;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          this.btnLoading = false;
          //  console.log("finalizou");
          this.valid = true;
          Overlay.hide();
          this.resetRecaptcha();
        });
    },
    ckCodeEmail(token: string) {
      const wrapper = this.$refs.formCriarContaValidaCkEmail as any;
      wrapper?.validate();
      if (!this.valid) {
        return false;
      }
      if (!this.form.codigo) {
        Snackbar.show("Informe o código de 6 digitos", "error");

        return false;
      } else if (this.form.codigo.length != 6) {
        Snackbar.show("Código inválido", "error");

        return false;
      }
      this.valid = true;

      Overlay.show();

      this.btnLoading = true;

      Api.post(
        "/v2/client/register/checkCodeEmail",
        {
          email: this.form.email,
          codigo: this.form.codigo,
          tokenCadastro: this.form.tokenCadastro,
        },
        {
          headers: {
            recaptcha: token,
          },
        }
      )
        .then((response) => {
          this.storage();
          this.valid = true;
          this.stepRegister = 3;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          this.btnLoading = false;
          //  console.log("finalizou");

          Overlay.hide();
          this.resetRecaptcha();
        });
    },
    onKeyUpCPFCNPJ() {
      //this.validCPFCNPJ = false;
      if (!this.form.cpfcnpj) {
        return false;
      }

      const inputLength = this.form.cpfcnpj.length;

      if (inputLength == 11) {
        this.showDataNascimento = true;
        this.validCPFCNPJ = true;
      } else if (inputLength >= 12) {
        this.showDataNascimento = false;
        this.validCPFCNPJ = true;
      }
    },

    criarConta(token: string) {
      console.log(this.form);
      const wrapper = this.$refs.formRegister as any;
      wrapper?.validate();
      if (!this.valid) {
        return false;
      }
      if (!this.passwordRules.valid_password) {
        const celPass = this.$refs.formSenha as any;
        this.$nextTick(() => {
          window.setTimeout(() => celPass?.focus(), 500);
        });
        this.resetRecaptcha();
        return false;
      }

      this.errors = [];
      this.btnLoading = true;
      Overlay.show();
      Api.post("/v2/client/register", this.form, {
        headers: {
          recaptcha: token,
        },
      })
        .then((response) => {
          sessionStorage.removeItem("dadosConta");
          sessionStorage.removeItem("afiliado");
          window.location.replace(
            "https://ezzepay.com.br/pay/abrir-conta-finalizado/"
          );
          // this.stepRegister = 8;
        })
        .catch((error: any) => {
          Overlay.hide();
          this.btnLoading = false;
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          } else if (status === 404) {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.btnLoading = false;
          //  console.log("finalizou");

          //Overlay.hide();
          this.resetRecaptcha();
        });
    },
    onSubmit: function () {
      (this.$refs.invisibleRecaptcha as HTMLFormElement).execute();
    },
    onVerifyCkEmail: function (response: string) {
      this.ckEmail(response);
    },
    onVerifyCkCPFCNPJ: function (response: string) {
      this.ckDoc(response);
    },
    onVerifyCkCodeEmail: function (response: string) {
      this.ckCodeEmail(response);
    },
    onVerifyCkCEP: function (response: string) {
      this.checkCEP(response);
    },
    onVerifyCkUsuario: function (response: string) {
      this.ckUser(response);
    },

    onVerifyCkRegister: function (response: string) {
      this.criarConta(response);
    },
    onVerifyCkLocalidade: function (response: string) {
      this.ckLocalidade(response);
    },

    onExpired: function () {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      (this.$refs.invisibleRecaptcha as HTMLFormElement).reset(); 
    },
  },
});
